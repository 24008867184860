import React from 'react'
import question from "../../images/question_mark.png"
import narcade from "../../logos/Fuaye/narcade.png"
import gamesup from "../../logos/Fuaye/gamesup.png"
import uskudar from "../../logos/Fuaye/uskudar.png"
import gamfed from "../../logos/Fuaye/gamfed.png"
import starverse from "../../logos/Fuaye/starverse.png"
import toyed from "../../logos/Fuaye/toyed.png"
import sensemotion from "../../logos/Fuaye/sensemotion.png"
import remoob from "../../logos/Fuaye/remoob.png"
import dojo from "../../logos/Fuaye/dojo.png"

const Stants = () => {
    return (
        <div className=' overflow-x-hidden w-100vw grid grid-cols-5 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5'>
            {/*
            <div className=' w-full  relative speakers-box overflow-hidden transition-all'>
                <img className=' w-full' alt='img' src={funverse} />
                <div className=' absolute w-full h-full opacity-0 top-[-100vh] speakers-information transition-all flex justify-center items-center px-4 overflow-x-hidden'>
                    <p className=' text-white cursor-pointer text-center info'>
                    Funverse Games
                    </p>
                </div>
            </div>
            <div className=' w-full  relative speakers-box overflow-hidden transition-all'>
                <img className=' w-full' alt='img' src={gamefactory} />
                <div className=' absolute w-full h-full opacity-0 top-[-100vh] speakers-information transition-all flex justify-center items-center px-4 overflow-x-hidden'>
                    <p className=' text-white cursor-pointer text-center info'>
                        Game Factory
                    </p>
                </div>
            </div>
            */}
            <div className=' w-full  relative speakers-box overflow-hidden transition-all'>
                <img className=' w-full' alt='img' src={question} />
                <div className=' absolute w-full h-full opacity-0 top-[-100vh] speakers-information transition-all flex justify-center items-center px-4 overflow-x-hidden'>
                    <p className=' text-white cursor-pointer text-center info'>
                        Yakında...
                    </p>
                </div>
            </div>
            <div className=' w-full  relative speakers-box overflow-hidden transition-all'>
                <img className=' w-full' alt='img' src={question} />
                <div className=' absolute w-full h-full opacity-0 top-[-100vh] speakers-information transition-all flex justify-center items-center px-4 overflow-x-hidden'>
                    <p className=' text-white cursor-pointer text-center info'>
                        Yakında...
                    </p>
                </div>
            </div>
            <div className=' w-full  relative speakers-box overflow-hidden transition-all'>
                <img className=' w-full' alt='img' src={question} />
                <div className=' absolute w-full h-full opacity-0 top-[-100vh] speakers-information transition-all flex justify-center items-center px-4 overflow-x-hidden'>
                    <p className=' text-white cursor-pointer text-center info'>
                        Yakında...
                    </p>
                </div>
            </div>
            <div className=' w-full  relative speakers-box overflow-hidden transition-all'>
                <img className=' w-full' alt='img' src={question} />
                <div className=' absolute w-full h-full opacity-0 top-[-100vh] speakers-information transition-all flex justify-center items-center px-4 overflow-x-hidden'>
                    <p className=' text-white cursor-pointer text-center info'>
                        Yakında...
                    </p>
                </div>
            </div>
            <div className=' w-full  relative speakers-box overflow-hidden transition-all'>
                <img className=' w-full' alt='img' src={question} />
                <div className=' absolute w-full h-full opacity-0 top-[-100vh] speakers-information transition-all flex justify-center items-center px-4 overflow-x-hidden'>
                    <p className=' text-white cursor-pointer text-center info'>
                        Yakında...
                    </p>
                </div>
            </div>
            <div className=' w-full  relative speakers-box overflow-hidden transition-all'>
                <img className=' w-full' alt='img' src={question} />
                <div className=' absolute w-full h-full opacity-0 top-[-100vh] speakers-information transition-all flex justify-center items-center px-4 overflow-x-hidden'>
                    <p className=' text-white cursor-pointer text-center info'>
                        Yakında...
                    </p>
                </div>
            </div>
            <div className=' w-full  relative speakers-box overflow-hidden transition-all'>
                <img className=' w-full' alt='img' src={question} />
                <div className=' absolute w-full h-full opacity-0 top-[-100vh] speakers-information transition-all flex justify-center items-center px-4 overflow-x-hidden'>
                    <p className=' text-white cursor-pointer text-center info'>
                        Yakında...
                    </p>
                </div>
            </div>
            <div className=' w-full  relative speakers-box overflow-hidden transition-all'>
                <img className=' w-full' alt='img' src={question} />
                <div className=' absolute w-full h-full opacity-0 top-[-100vh] speakers-information transition-all flex justify-center items-center px-4 overflow-x-hidden'>
                    <p className=' text-white cursor-pointer text-center info'>
                        Yakında...
                    </p>
                </div>
            </div>
            <div className=' w-full  relative speakers-box overflow-hidden transition-all'>
                <img className=' w-full' alt='img' src={question} />
                <div className=' absolute w-full h-full opacity-0 top-[-100vh] speakers-information transition-all flex justify-center items-center px-4 overflow-x-hidden'>
                    <p className=' text-white cursor-pointer text-center info'>
                        Yakında...
                    </p>
                </div>
            </div>
            <div className=' w-full  relative speakers-box overflow-hidden transition-all'>
                <img className=' w-full' alt='img' src={question} />
                <div className=' absolute w-full h-full opacity-0 top-[-100vh] speakers-information transition-all flex justify-center items-center px-4 overflow-x-hidden'>
                    <p className=' text-white cursor-pointer text-center info'>
                        Yakında...
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Stants